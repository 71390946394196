import {reactive} from "vue";

const theme = reactive({
    colorScheme: "dark",
    isDark() {
        return theme.colorScheme === "dark";
    },
    isLight() {
        return theme.colorScheme === "light";
    },
    changeToDark() {
        changeTheme("dark");
    },
    changeToLight() {
        changeTheme("light");
    },
    toggle() {
        if (theme.isDark()) {
            theme.changeToLight();
        } else {
            theme.changeToDark();
        }
    }
});

function changeTheme(colorScheme) {
    const htmlNode = document.documentElement;
    switch (colorScheme) {
        case "dark":
            theme.colorScheme = colorScheme;
            window.localStorage.preferedColorScheme = colorScheme;
            htmlNode.classList.add("dark");
            htmlNode.style.colorScheme = "dark";
            break;
        case "light":
            theme.colorScheme = colorScheme;
            window.localStorage.preferedColorScheme = colorScheme;
            htmlNode.classList.remove("dark");
            htmlNode.style.colorScheme = "light";
            break;
        default:
            if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                changeTheme("dark");
            } else {
                changeTheme("light");
            }
            break;
    }
}

changeTheme(window.localStorage.preferedColorScheme);
export default theme;
